import React from 'react';

import { Box } from '@core';

import { Reviews } from '@containers';

const Feedback = ({ title, description, padding }) => (
  <Box {...padding}>
    <Reviews title={title} description={description} padding={{ py: 40 }} />
  </Box>
);

export default Feedback;
